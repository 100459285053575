@charset 'UTF-8';

.app__body {
    .suggestion--selected {
        background-color: rgba(var(--center-channel-color-rgb), 0.08);
    }
}

.suggestion-list__icon {
    display: inline-flex;
    justify-content: center;
    opacity: 0.56;
    fill: v(center-channel-color);
    flex: 0 0 1.6rem;

    &--standard {
        font-size: 1.2rem;
        margin: 0 12px 0 0;
        flex: 0 0 2.4rem;
        width: 2rem;
        height: 2rem;
    }

    &--large {
        font-size: 2rem;
        margin: 0 12px 0 0;
        flex: 0 0 2.4rem;
        width: 2.4rem;
        height: 2.4rem;
    }

    .status--group {
        margin: 0 0 0 -1px;
    }
}

.suggestion-list {
    width: 100%;
    z-index: 100;

    .post-right__scroll & {
        margin: 0;
    }

    .badge {
        position: absolute;
        top: 8px;
        right: 10px;
        color: rgb(61, 60, 64);
        background: #CECECF;
        font-size: 10px;
    }

    .LoadingSpinner {
        margin: 0 24px;
        display: block;
        height: 40px;
        position: relative;
        top: 4px;
    }
}

.suggestion-list--top {
    bottom: 100%;
    position: absolute;
}

.suggestion-list--bottom {
    position: absolute;
    top: 0;
}

.suggestion-list__content {
    border-radius: 4px;
    -webkit-overflow-scrolling: touch;
    background-color: v(center-channel-bg);
    border: 1px solid v(center-channel-color-16);
    max-height: 292px;
    overflow-x: auto;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
    overflow-y: auto;
    padding-bottom: 12px;
    max-width: 100%;
    width: 496px;

    &::-webkit-scrollbar-track {
        width: 0;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border: 1px solid v(center-channel-bg);
        background-color: rgba(var(--center-channel-color-rgb), 0.24) !important;
        border-radius: 4px;
    }

    .Avatar {
        margin: 0 12px 0 0;
    }

    .mentions__name {
        font-size: inherit;
        height: 4rem;
        display: flex;
        align-items: center;
        padding: 0 2.4rem;
    }

    .modal & {
        max-height: 205px;
        width: 100%;
    }

    .suggestion-list__info_user {
        display: flex;
    }
}

.slash-command {
    display: flex;
    align-items: flex-start;
    padding: 1rem 2.4rem;

    &:first-child {
        margin: 12px 0 0 0;
    }
}

.slash-command__icon {
    background: rgba(var(--center-channel-color-rgb), 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 3.2rem;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 600;

    span {
        opacity: .56;
    }
}

.slash-command__info {
    line-height: 1;
    margin-left: 12px;
}

.slash-command__desc {
    @include opacity(.56);
    margin: 2px 0 0;
    font-size: 12px;
    line-height: 1.6;
}

.suggestion-list__content--top {
    bottom: 0;
    position: absolute;
}

.suggestion-list__content--bottom {
    top: 0;
    position: absolute;
}

.suggestion-list__divider {
    font-size: 1.2rem;
    color: v(center-channel-color-60);
    height: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1.2rem 0 0 0;
    padding: 0 0 0 2.4rem;
    position: relative;
    text-transform: uppercase;
    font-weight: 600;

    &:first-child {
        &:before {
            display: none;
        }
    }

    &:before {
        content: '';
        height: 1px;
        background: rgba(var(--center-channel-color-rgb), 0.08);
        position: absolute;
        top: -7px;
        left: 0;
        width: 100%;
    }
}

.suggestion-list__no-results {
    line-height: 21px;
    margin: 7px 10px 2px;
    position: relative;

    >span {
        display: inline-block;
        font-size: .9em;
        padding: 0 10px 0 5px;
        position: relative;
        z-index: 5;
    }
}
