@charset 'UTF-8';

.app__body {
    .custom-textarea {
        border-radius: 4px;
        background: transparent;
        border: none;
        box-shadow: inset 0 0 0 1px rgba(v(center-channel-color-rgb), 0.24);
        height: 100%;
        line-height: 20px;
        min-height: 46px;
        overflow: hidden;
        resize: none;
        transition: none;
        white-space: pre-wrap;
        word-wrap: break-word;

        &:focus {
            box-shadow: inset 0 0 0 2px rgba(v(center-channel-color-rgb), 0.32);
        }

        &.bad-connection {
            background: #ffffac !important;
            color: #d04444 !important;
        }
    }
}

.textarea-wrapper {
    min-height: 37px;
    position: relative;

    &.textarea-wrapper--preview {
        > div {
            &:first-child {
                display: none;
            }
        }
    }

    .textbox-preview-area {
        ul {
            white-space: normal;
        }

        left: 0;
        position: relative;
        top: 0;
        z-index: 2;

        &:focus {
            border-color: rgba(v(center-channel-color-rgb), 0.4);
        }

        p {
            margin: 0;
            white-space: pre-wrap;
        }

        ul + p,
        ol + p {
            margin-top: .6em;
        }

        p + ul,
        p + ol {
            margin-top: .6em;
        }
    }

    .textbox-preview-link {
        margin-right: 8px;
    }
}

.help__format-text {
    @include single-transition(all, .3s, ease-in, .3s);
    opacity: 0;
    display: inline-block;
    font-size: .85em;
    vertical-align: bottom;
    white-space: nowrap;

    .modal & {
        white-space: normal;
    }

    b,
    i,
    span {
        margin: 0 2px;
        position: relative;
        top: -1px;
    }

    b {
        @include opacity(.9);
    }

    code {
        background: transparent;
        padding: 0;
    }

    .textbox-preview-link {
        cursor: pointer;
        font-size: 13px;
        margin-left: 15px;
    }
}

.file-overlay {
    color: $white;
    font-size: em(20px);
    font-weight: 600;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 11;

    .overlay__indent {
        @include clearfix;
        @include alpha-property(background-color, $black, .6);
        height: 100%;
        position: relative;
    }

    &.center-file-overlay {
    }

    &.right-file-overlay {
        font-size: em(18px);

        .overlay__circle {
            height: 300px;
            margin: -150px 0 0 -150px;
            width: 300px;
        }

        .overlay__files {
            margin: 60px auto 15px;
            width: 150px;
        }
    }

    .overlay__circle {
        @include border-radius(500px);
        @include alpha-property(background, $black, .7);
        height: 370px;
        left: 50%;
        margin: -185px 0 0 -185px;
        pointer-events: none;
        position: absolute;
        top: 50%;
        width: 370px;
    }

    .overlay__files {
        display: block;
        margin: 75px auto 20px;
    }

    .overlay__logo {
        @include opacity(.3);
        bottom: 30px;
        left: 50%;
        margin-left: -50px;
        position: absolute;
    }

    .fa {
        display: inline-block;
        font-size: 1.1em;
        margin-right: 8px;
    }
}

#post-list {
    @include flex(1 1 auto);
    height: 100%;
    overflow-y: hidden;
    position: relative;

    .inactive {
        display: none;
    }

    .post-list__loading {
        @include opacity(.5);
        font-size: .9em;
        font-style: italic;
        padding: 1em 0;
        text-align: center;

        i {
            margin-right: 2px;
        }

        &.post-list__loading-search {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .post-list-holder-by-time {
        height: 100%;
        width: 100%;
        position: absolute;
        &.active {
            display: inline;
        }
        &.normal_post_list {
            overflow-y: scroll;
            padding: 1em 0 0;
            .post-list__table {
                display: table;
            }
            .post-list__content {
                vertical-align: bottom;
                height: unset;
                padding: 14px 0px 7px;
                display: table-cell;
            }
        }
    }

    .more-messages-text {
        border: none;
        display: block;
        font-size: 13px;
        margin: 5px 0 10px;
        outline: none;
        text-align: center;
        width: 100%;
    }

    .new-messages__button {
        @include opacity(0);
        @include translateX(-50%);
        bottom: 0;
        font-size: 13.5px;
        left: 50%;
        margin: 5px auto;
        position: absolute;
        text-align: center;
        visibility: hidden;
        z-index: 3;

        .fa {
            font-size: 1.2em;
            font-weight: bold;
            margin-right: .5rem;
            position: relative;
            top: 1px;
        }

        .icon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
        }

        div {
            @include border-radius(50px);
            cursor: pointer;
            padding: 0 20px;
            height: 28px;
            display: flex;
            align-items: center;
        }

        body.enable-animations & {
            @include transition-property(opacity, visibility);
            @include transition-duration($transition-quick);
            @include transition-delay(0s);
            @include transition-timing-function(ease-in, step-end);
        }

        &.visible {
            @include opacity(1);
            visibility: visible;

            body.enable-animations & {
                @include transition-property(opacity, visibility);
                @include transition-duration($transition-quick);
                @include transition-delay(0s);
                @include transition-timing-function(ease-out, step-start);
            }
        }
    }
}

.post-list__timestamp {
    @include opacity(0);
    @include single-transition(all, .6s, ease);
    @include translateY(-45px);
    display: none;
    left: 0;
    position: absolute;
    text-align: center;
    top: 8px;
    width: 100%;
    z-index: 50;

    &.scrolling {
        @include translateY(0);
        @include opacity(.9);
    }

    &.rhs {
        top: 98px;
    }

    &.toastAdjustment {
        top: 50px;
    }

    > div {
        @include border-radius(3px);
        @include font-smoothing(initial);
        background: $primary-color;
        border: 1px solid;
        color: $white;
        display: inline-block;
        font-size: 12px;
        line-height: 25px;
        padding: 0 8px;
        text-align: center;
    }
}

.post-list__arrows {
    @include opacity(0);
    @include single-transition(all, .6s);
    background-repeat: no-repeat;
    bottom: 0;
    display: none;
    fill: #444444;
    height: 40px;
    left: 9px;
    position: absolute;
    text-align: center;
    width: 40px;
    z-index: 50;

    svg {
        color: inherit;
        height: 28px;
        width: 28px;
    }

    &.scrolling {
        @include opacity(1);
        display: block;
    }
}

.post-create-message {
    padding: 15px;
}

.post-create__container {
    @include flex(0 0 auto);
    width: 100%;
    z-index: 12;

    label {
        font-weight: normal;
    }

    form {
        margin: 0 auto;
        padding: 0 15px 0;
        width: 100%;
    }

    .center {
        max-width: 1028px;
    }

    .custom-textarea {
        bottom: 0;
        max-height: calc(50vh - 40px);
        padding: 13px 0 12px 16px;
        resize: none;
        cursor: auto;

        &:not(.custom-textarea--emoji-picker) {
            padding-right: 40px;
        }

        &.custom-textarea--emoji-picker {
            padding-right: 90px;
        }

        &.textbox-preview-area {
            overflow-y: auto;
        }

        @include input-placeholder {
            color: inherit;
        }
    }

    .emoji-picker {
        position: absolute;
        right: 0;
        top: -361px;
    }

    .scroll {
        .post-body__actions {
            right: calc(var(--detected-scrollbar-width) - 4px);
        }

        .custom-textarea {
            -ms-overflow-style: auto;
            -webkit-overflow-scrolling: touch;
            overflow: auto;

            &:not(.custom-textarea--emoji-picker) {
                padding-right: 50px;
            }

            &.custom-textarea--emoji-picker {
                padding-right: 90px;
            }
        }
    }

    .post-create-body {
        padding: 0 0 2px;
        position: relative;

        .post-body__cell {
            position: relative;
            vertical-align: top;
        }

        .send-button {
            @include single-transition(all, .15s);
            border-left: 1px solid transparent;
            cursor: pointer;
            display: none;
            font-size: 17px;
            height: 100%;
            line-height: 49px;
            padding-right: 4px;
            text-align: center;
            vertical-align: bottom;
            width: 45px;

            &:active {
                @include opacity(.75);
            }

            &.disabled {
                i {
                    color: inherit;
                }
            }

            .android &,
            .ios & {
                display: block;
            }
        }


        .icon--emoji-picker {
            @include opacity(.5);
            @include single-transition(all, .15s);
            cursor: pointer;

            &:hover,
            &:active {
                @include opacity(.9);
                box-shadow: none;
            }

            .icon--attachment {
                @include opacity(.5);
                display: inline-block;
                position: relative;
                vertical-align: top;

                & + input {
                    cursor: pointer;
                    direction: ltr;
                    filter: alpha(opacity=0);
                    font-size: 23px;
                    height: 100%;
                    margin: 0;
                    opacity: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                }

                &:hover {
                    @include opacity(.9);
                }
            }
        }
    }

    .msg-typing {
        @include opacity(.7);
        display: block;
        font-size: .95em;
        height: 20px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .channel-archived__message {
        padding: 25px;
        border-top-width: 1px;
        border-top-style: solid;
        text-align: center;
    }

    .channel-archived__close-btn {
        margin: 10px;
    }
}

.post-body__actions {
    margin: 0 1px 0 0;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;

    > div {
        margin: 0 4px 0 0;
    }

    .post-action {
        background: transparent;
        border: none;
        border-radius: 4px;
        margin: 5px 0;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        fill: rgba(v(center-channel-color-rgb), 0.56);
        color: rgba(v(center-channel-color-rgb), 0.56);

        &:hover {
            background: rgba(v(center-channel-color-rgb), 0.08);
            fill: rgba(v(center-channel-color-rgb), 0.72);
            color: rgba(v(center-channel-color-rgb), 0.72);
        }

        &:active,
        &--active,
        &--active:hover {
            background: rgba(var(--button-bg-rgb), 0.08);
            fill: v(button-bg);
            color: v(button-bg);
        }
    }

    &.btn-file__disabled {
        @include opacity(.1);

        &:hover,
        &:active {
            @include opacity(.1);
        }
    }

    .icon--attachment {
        cursor: pointer;
        overflow: hidden;
        position: relative;
        vertical-align: top;

        & + input {
            @include opacity(0);
            cursor: pointer;
            direction: ltr;
            font-size: 23px;
            height: 100%;
            margin: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            pointer-events: none;
        }
    }
}

.post-create-footer {
    font-size: 13px;
    padding: 6px 0 0;
    position: relative;

    .help__text {
        text-align: right;

        a,
        button {
            margin-left: 10px;
        }
    }

    .post-error {
        @include opacity(.55);
        display: inline-block;
        font-size: .85em;
        font-weight: normal;
        margin-bottom: 0;
        position: absolute;
        top: 4px;
    }
}

.post-list__table {
    height: 100%;
    width: 100%;

    .post-list__content {
        height: 100%;
        overflow: hidden;

        .dropdown-menu {
            &.bottom {
                bottom: 19px;
                top: auto;
            }
        }
    }
    .loading-screen {
        position: relative;
        height: calc(40px + 1em);
        padding: 0px;
    }
}

.post {
    overflow: hidden;
    max-width: 100%;
    padding: 8px .5em 0 1.5em; // If this needs to be changed then .post-row__padding needs to be adjusted accordingly
    position: relative;
    word-wrap: break-word;

    &:hover {
        background-color: rgba(v(center-channel-color-rgb), 0.04);
    }

    .browser--ie & {
        .post__header {
            .col__reply {
                .comment-icon__container {
                    flex: 0 1 auto;
                    align-items: center;
                }

                > .open, > div {
                    flex: 0 1 30px;
                    align-items: center;

                    &:first-child {
                        flex: 0 1 25px;
                    }
                }
            }
        }
    }

    &.post--system {
        .post__header {
            .post-menu {
                min-width: 0;
            }
        }
    }

    &:hover,
    &.a11y--active {
        @include legacy-pie-clearfix;
        overflow: visible;

        .dropdown,
        .comment-icon__container,
        .card-icon__container,
        .reacticon,
        .post-menu__item,
        .post__reply,
        .post-reaction,
        .post__remove {
            visibility: visible;
        }

        .permalink-icon {
            visibility: visible;
        }

        .post-add-reaction {
            .Reaction {
                visibility: visible;
                @include opacity(1);
            }
        }
    }

    &.post--hovered,
    &.a11y--active {
        @include legacy-pie-clearfix;
        overflow: visible;

        .dropdown,
        .comment-icon__container,
        .card-icon__container,
        .post__reply,
        .post__remove,
        .post__dropdown,
        .reacticon__container,
        .permalink-icon {
            visibility: visible;
        }

        .post__body {
            background: transparent !important;
        }
    }

    &.post--hide-controls {
        .post__header {
            .post-menu {
                display: none;
            }
        }
    }

    &.post--thread {
        &.same--user {
            padding: 0 .5em 0 1em;

            .post__header {
                margin-bottom: 0;

                .post__header--info {
                    position: absolute;
                    left: -5px;

                    .post__time {
                        font-size: 0.7em;
                        display: inline-block;
                        left: -14px;
                        line-height: 20px;
                        position: absolute;
                        text-align: right;
                        text-rendering: auto;
                        top: 4px;
                        width: 51px;

                    }
                }
            }
        }
    }

    &.post--compact {
        &.post--thread {
            .post__header {
                padding-top: 3px;
            }
        }

        &.post--system {
            .status {
                visibility: hidden;
            }

            > div {
                margin-bottom: 0;
            }
        }

        .card-icon__container {
            margin: 0 7px 0 -4px;
        }

        .post-message {
            overflow: inherit;
        }

        .post-message--collapsed + .post-image__columns {
            &:before {
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        .post__img {
            padding-top: 0;
        }

        .attachment {
            clear: both;
            padding-top: 1px;

            &.attachment--pretext {
                clear: none;
                padding: 0;
            }

            .attachment__body__wrap {
                .btn-close {
                    left: -2px;
                }
            }
        }

        &.post--comment {
            .attachment {
                .attachment__body__wrap {
                    .btn-close {
                        left: -13px;
                    }
                }
            }
        }

        .auto-responder {
            background: alpha-color($white, .7);
        }

        .status-wrapper {
            cursor: auto;
            height: 14px;
            pointer-events: none;

            .status {
                bottom: auto;
                height: 13px;
                left: -2px;
                position: relative;
                right: auto;
                top: auto;
                width: 13px;
            }

            svg {
                top: 1px;
            }

            &:after {
                bottom: 0;
            }
        }

        blockquote {
            display: flex;
            padding: 3px 0px 3px 20px;
            flex-direction: column;

            &:before {
                font-size: 15px;
                left: 0;
                top: 0;
            }
        }

        .search-item-snippet {
            blockquote {
                margin-top: 0;
            }
        }

        .markdown__heading {
            clear: both;
            font-size: 1em;
            margin: 0;
        }

        .post__header {
            display: flex;
            height: 22px;
            margin-bottom: 0;
            padding: 0;
            width: auto;
        }

        .post__body {
            background: transparent !important;
            line-height: 1.5;
            padding: 2px 0 0;

            .post--edited {
                position: relative;
                top: -1px;
            }

            .img-div {
                max-height: 150px;
                max-width: 150px;
            }

            p {
                line-height: inherit;
            }

            ol,
            ul {
                clear: both;
                padding-left: 20px;
            }

            div:not(.image-loading__container) {
                margin-bottom: 0;
            }

            .post-image__column {
                margin: 3px 10px 3px 0;
                padding: 0;
            }
        }

        .post-image__column {
            @include border-radius(2px);
            font-size: .9em;
            height: 26px;
            line-height: 25px;
            max-width: 250px;
            min-width: 150px;
            padding: 0 7px;

            .post-image__thumbnail {
                display: none;
            }

            .post-image__details {
                background: transparent;
                border: none;
                padding: 0;
                width: 100%;

                span {
                    display: none;
                }

                svg {
                    position: relative;
                    top: 2px;
                }

                .icon {
                    display: block;
                    float: left;
                    margin-right: 5px;
                    opacity: .6;
                }
            }

            .post-image__download {
                opacity: 1;
                padding: 0;
                position: relative;
                width: auto;
            }

            .post-image__name {
                @include clearfix;
                display: block;
                margin: 0;
                padding: 0 5px;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;

                i {
                    font-size: .9em;
                    margin-right: 5px;
                    opacity: .5;
                }
            }

            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .post__img {
            padding-top: 1px;
            width: 16px;

            img {
                display: none;
            }
        }
    }

    .post__img > :hover {
        cursor: pointer;
    }

    .post--fail {
        position: relative;
    }

    .post-edited__indicator {
        @include opacity(.6);
        font-size: .87em;
        -webkit-user-select: none; /* Chrome all / Safari all */
        -moz-user-select: none;    /* Firefox all */
        -ms-user-select: none;     /* IE 10+ */
        user-select: none;
    }

    .emoticon {
        vertical-align: top;
    }

    p {
        font-size: 13.5px;
        line-height: 1.6em;
        margin: 0;
        white-space: pre-wrap;
        width: 100%;
        word-break: break-word;
    }

    .post__header--info {
        padding: 0;
    }

    &.post--root {
        .comment-icon__container {
            visibility: visible;
        }
    }

    &.post--comment {
        .post__body {
            border-left: 4px solid $gray;
            padding-left: 7px;

            &.mention-comment {
                border-left: 4px solid $yellow;
                border-color: $yellow;
            }
        }

        .attachment {
            .attachment__body__wrap {
                .btn-close {
                    left: -11px;
                }
            }
        }
    }

    &.same--root {
        &.same--user {
            padding: 0 .5em 0 1.5em;

            &:hover,
            &.post--hovered,
            &.a11y--active {
                .post__time {
                    @include opacity(.5);
                }
            }

            .post__img {
                .status-wrapper {
                    display: none;
                }
            }

            .post__header {
                height: 0;
                margin: 0;

                .col__name {
                    display: none;
                }

                .post-menu {
                    top: -1px;
                }
            }

            .post-pre-header {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        &.post--comment {
            .post__link {
                display: none;
            }

            &.same--user {
                .post__img {
                    .status-wrapper {
                        display: none;
                    }
                }

                &.post--bot {
                    .post__header {
                        height: auto;
                        float: none;
                    }

                    &.post--compact {
                        .post__header {
                            float: left;
                        }
                    }

                    .col__name {
                        display: flex;
                    }

                    .post__permalink {
                        position: relative;
                        top: auto;
                        left: auto;
                        width: auto;
                    }

                    .post__time {
                        opacity: 0.6;
                    }

                    .post__img {
                        .status-wrapper {
                            display: inline;
                        }
                    }
                }
            }
        }
    }

    &.other--root {
        .comment-icon__container {
            &.icon--show {
                visibility: visible;
            }
        }

        &.post--comment {
            .popover {
                margin-top: 84px;

                > .arrow {
                    top: 21px !important;
                }
            }

            .post__header {
                .post-menu {
                    top: -4px;
                }
            }
        }
    }

    .post-pre-header {
        display: flex;
        height: 25px;
        padding-bottom: 10px;

        .post-pre-header__icons-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 9px; // If the padding of post__img changes, this needs to be adjusted accordingly
            width: 52px; // If the width of post__img changes, this needs to be adjusted accordingly
            margin-left: 5px; // if left margin of post__content changes, this needs to be adjusted accordingly

            .icon--post-pre-header {
                height: 10px;
                width: 10px;
                fill: var(--link-color);
                color: var(--link-color);
                font-size: 12px;

                &:nth-of-type(even) {
                    margin-left: 9px;
                }

                &.icon-pin::before {
                    line-height: 10px;
                }
            }
        }

        .post-pre-header__text-container {
            color: var(--link-color);
            font-size: 12px;
            line-height: 15px;

            .post-pre-header__link-separator {
                margin: 0px 5px;
                color: rgba(var(--center-channel-color-rgb), 0.32);
                font-size: 4px;
                vertical-align: top;
            }
        }
    }

    .post__content {
        display: table;
        margin: 0 auto;
        padding: 0 8px 0 5px; // if the left margin changes, the margin of post-pre-header__icons-container needs to be adjusted accordingly
        position: relative;
        table-layout: fixed;
        width: 100%;

        > div {
            display: table-cell;
            vertical-align: top;
        }
    }

    .center {
        max-width: 1000px;
    }

    &:hover {
        .post__header {
            padding: 0 55px 0 0;
        }
    }

    .post__header {
        display: flex;
        margin-bottom: 2px;
        white-space: nowrap;
        width: 100%;

        .col__name {
            display: flex;
            font-weight: 600;
            margin-right: 7px;
            text-overflow: ellipsis;
            flex: 0 auto;
            min-width: 0;

            .user-popover {
                @include clearfix;
                text-overflow: ellipsis;
                text-align: left;
                vertical-align: top;
                white-space: nowrap;
                -moz-user-select: all;    /* Firefox all */
            }

            .colon {
                display: none;
                font-weight: 900;
                margin-left: 2px;
            }
        }

        .col__remove {
            position: absolute;
            right: 10px;
        }

        .permalink-popover {
            min-width: 0;

            .popover-content {
                padding: 5px;
            }

            .form-control,
            .btn {
                font-size: 13px;
                height: 30px;
                line-height: 30px;
                padding: 0 8px;
            }
        }
    }

    .post__img {
        padding-right: 10px; // if this changes, the padding of post-pre-header__icons-container needs to be adjusted accordingly
        text-align: right;
        width: 53px; // if this changes, the width of post-pre-header__icons-container needs to be adjusted accordingly

        .icon {
            fill: v(center-channel-color);

            svg {
                height: 32px;
                width: 32px;
            }
        }

        path {
            fill: inherit;
        }

        .profile-icon {
            display: inline-flex;
            overflow: hidden;
            justify-content: center;
            align-items: center;

            &.emoji {
                img {
                    @include border-radius(unset);
                    height: 28px;
                    width: 28px;
                    min-width: 28px;
                    -webkit-user-select: none; /* Chrome all / Safari all */
                    -moz-user-select: none;    /* Firefox all */
                    -ms-user-select: none;     /* IE 10+ */
                    user-select: none;
                }
            }
        }
    }

    .post__embed-container {
        @include single-transition(max-height, .5, ease);
        display: block;
        padding-top: 5px;
        max-height: 1000px;
        overflow: hidden;

        &[hidden] {
            max-height: 0;
        }
    }

    .dropdown {
        display: inline-block;
        top: -1px;
        vertical-align: middle;
        visibility: hidden;

        &:last-child {
            margin-right: 0;
        }

        .dropdown-menu {
            left: auto;
            min-width: 130px;
            padding: 2px 0;
            right: 0;
            top: 20px;

            li {
                display: block;
            }

            a {
                padding: 5px 15px;
            }
        }
    }

    .post__remove {
        @include opacity(.5);
        color: inherit;
        display: inline-block;
        float: right;
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        font-weight: 600;
        height: 20px;
        line-height: 20px;
        position: relative;
        right: -10px;
        text-decoration: none;
        vertical-align: top;
        visibility: hidden;
        width: 20px;

        &:hover {
            @include opacity(.8);
        }
    }

    .post-add-reaction {
        display: inline-block;

        .Reaction {
            @include opacity(0);
            visibility: hidden;
        }
    }

    .post__body {
        @include legacy-pie-clearfix;
        padding: 0 0 0.2em;
        width: 100%;
        word-wrap: break-word;

        &.post--ephemeral {
            padding-right: 20px !important;
        }

        p + p {
            margin: 0.5em 0 0;
        }

        ul,
        ol {
            font-size: 13.5px;

            p {
                margin-bottom: 0;
            }

            li {
                p {
                    display: inline;
                }

                ul {
                    font-size: 1em;
                    margin: 0;
                    padding: 0 0 0 20px;
                }
            }

            li.list-item--task-list ul,
            li.list-item--task-list ol {
                margin-left: 20px;
            }

            li.list-item--task-list ul {
                padding-left: 0;
            }

            li:not(.list-item--task-list) li.list-item--task-list,
            li:not(.list-item--task-list) li.list-item--task-list ~ li {
                margin-left: -20px;
            }

            li input[type='checkbox']:disabled {
                cursor: default;
                vertical-align: top;
            }
        }

        ul li.list-item--task-list,
        ul li.list-item--task-list ~ li {
            list-style-type: none;
        }

        ul li.list-item--task-list ~ li:not(.list-item--task-list) {
            text-indent: 3px;

            &:before {
                content: '\2022';
                margin-right: 8px;
            }
        }

        ul + p,
        ol + p {
            margin-top: .6em;
        }

        p + ul,
        p + ol {
            margin-top: .6em;
        }

        .pending-post-actions {
            background: alpha-color($black, .7);
            color: $white;
            font-size: .9em;
            padding: 5px 7px;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 100;

            a {
                color: $white;
            }
        }
    }

    .post-reaction-list {
        display: flex;
        flex-wrap: wrap;
        padding: 4px 0 0;
        align-items: center;
        min-height: 30px;
        position: relative;
        z-index: 5;

        .post-add-reaction-emoji-picker-open {
            display: inline-block;

            .Reaction {
                @include opacity(1);
                visibility: visible;
            }
        }
    }

    .post-add-reaction-emoji-picker-open {
        .Reaction {
            background-color: rgba(var(--button-bg-rgb), 0.08);
            color: v(button-bg);
            fill: v(button-bg);
        }
    }

    .post__link {
        font-size: 13px;
        margin: 2px 0 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-user-select: none; /* Chrome all / Safari all */
        -moz-user-select: none;    /* Firefox all */
        -ms-user-select: none;     /* IE 10+ */
        user-select: none;

        a {
            font-weight: bold;

            &.user_name:hover {
                text-decoration: none;
            }
        }
    }

    .post__embed-visibility {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        margin: 0;
        text-rendering: auto;
        position: relative;
        z-index: 2;
        width: 2.4rem;
        height: 1.6rem;

        &.pull-left {
            margin: 2px 0px 0 -4px;
        }

        &:hover {
            text-decoration: none;
        }

        &:before {
            content: '\f0da';
}

        &[data-expanded='true']:before {
            content: '\f0d7';
        }
    }

    .post__visibility {
        @include opacity(.6);
        font-size: .9em;
        padding: 4px;
    }

    .post__time {
        @include opacity(.6);
        font-size: .9em;
        margin-right: 4px;
    }

    .post__permalink {
        color: inherit;
        display: inline-block;

        &:hover,
        &:focus {
            color: inherit;
        }

        &:focus {
            text-decoration: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .post_permalink_mobile_view {
        color: inherit;
        display: inline-block;

        &:hover,
        &:focus {
            color: inherit;
            text-decoration: none;
        }
    }

    .post-loading-gif {
        height: 10px;
        margin-top: 6px;
        width: 10px;
    }

    .post-fail {
        color: #d58a8a;
    }

    .post-waiting {
        color: #999999;
        position: relative;

        .spinner {
            width: 20px;
            position: absolute;
            right: 8px;
            top: 4px;
        }

        .post-message {
            padding-right: 24px;
        }
    }

    .permalink-icon {
        color: $primary-color;
        display: inline-block;
        visibility: hidden;
    }

    .post__reply {
        display: inline-block;
        margin-right: 6px;
        visibility: hidden;

        svg {
            fill: inherit;
            position: relative;
            top: 3px;
            width: 18px;
        }
    }

    .comment-icon__container {
        display: inline-block;
        fill: $primary-color;
        position: relative;
        text-decoration: none;
        top: 1px;
        visibility: hidden;

        &:focus {
            outline: none;
        }

        &.icon--visible {
            visibility: visible;
        }

        svg {
            height: 17px;
            width: 17px;
        }

        .comment-count {
            margin-left: 2px;
        }

        .comment-icon {
            display: inline-block;
            fill: inherit;
            margin: 0 1px 0 5px;
            position: relative;
            top: 1px;
            vertical-align: top;
        }

        path {
            fill: inherit;
        }
    }

    .reacticon__container {
        display: inline-block;
        fill: $primary-color;
        font-size: 16px;
        vertical-align: top;
        visibility: hidden;

        svg {
            height: 14px;
            position: relative;
            top: 1px;
            width: 14px;
        }
    }

    .card-icon__container {
        @extend %btn-transition;
        @include opacity(.24);
        margin: 0 6px 0 4px;
        vertical-align: top;
        top: 2px;
        position: relative;
        z-index: 1;

        &:hover {
            @include opacity(.4);
        }

        &.active {
            @include opacity(1);
        }

        svg {
            height: 14px;
            width: 14px;
        }
    }

    .web-embed-data {
        @include border-radius(2px);
        @include alpha-property(background, $black, 0.05);
        height: 50px;
        overflow: hidden;
        padding: 2px 0 0 10px;
        text-overflow: ellipsis;

        .embed-title {
            color: #555555;
            font-weight: 600;
            margin: 3px 0 1px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .embed-description {
            color: #888888;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .embed-link {
            display: none;
        }
    }
}

.post--pinned-or-flagged {
    background-color: rgba(var(--mention-highlight-bg-rgb), 0.12);
}

.Badge {
    &.badge-autocomplete {
        margin: 0px 4px;
        padding: 0px 5px;
        display: inline;
    }
    &.badge-popoverlist {
        margin: 0px 4px;
        display: inline;
    }
    &.badge-admin {
        margin: 0px 4px;
        background: rgba(61, 60, 64, 0.15);
        display: inline;
    }
}

.permalink-text {
    overflow: hidden;
}

.permalink-popover {
    margin-left: 50px !important;
    min-width: 320px;
}

.post-message {
    position: relative;
    overflow: hidden;
}

.post-collapse {
    bottom: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;

    .post-collapse__gradient, .post-attachment-collapse__gradient {
        background: linear-gradient(transparent, v(center-channel-bg));
        position: relative;
    }

    .post-collapse__show-more, .post-attachment-collapse__show-more {
        background: v(center-channel-bg);
        pointer-events: auto;
    }
}

.post-collapse__show-more {
    background: v(center-channel-bg);
    padding-bottom: 10px;
}

.post-collapse__show-more, .post-attachment-collapse__show-more {
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 5;

    .post-collapse__show-more-button {
        @include single-transition(all, .15s, ease);
        @include border-radius(2px);
        &:hover {
            color: var(--center-channel-bg);
        }
        background: var(--center-channel-bg);
        border: 1px solid transparent;
        display: inline-block;
        flex-shrink: 0;
        font-size: 13px;
        font-weight: bold;
        line-height: 24px;
        margin: 0 8px;
        padding: 0 8px;
        position: relative;

        .fa {
            font-size: 1.4em;
            font-weight: bold;
            margin-right: 5px;
            position: relative;
            top: 2px;

            &.fa-angle-up {
                top: 1px;
            }
        }

        &:focus {
            outline: none;
        }
    }

    .post-collapse__show-more-line {
        display: inline-block;
        flex-basis: 200px;
        height: 1px;
        margin-top: 12px;
    }
}

.sidebar-right__card {
    .post-message--collapsed {
        max-height: 150px;
    }
}

.post-message--collapsed {
    .post-message__text-container {
        // If this max-height is changed, the MAX_POST_HEIGHT constant in
        // components/post/post_message_view/post_message_view.jsx must
        // be changed to match it
        max-height: 600px;
        .all-emoji {
            .emoticon {
                min-height: 32px;
                min-width: 32px;
                vertical-align: top;
            }
            .emoticon--unicode {
                font-size: 32px;
                line-height: 1.1;

                .os--windows & {
                    font-size: 29px;
                    left: -4px;
                    position: relative;
                }
            }
        }
    }

    .post-collapse__gradient, .post-attachment-collapse__gradient {
        height: 90px;
    }

    .post-collapse__show-more {
        bottom: 10px;
    }
}

.post-message--expanded {
    .post-collapse {
        position: relative;
    }

    .post-collapse__show-more, .post-attachment-collapse__show-more {
        position: relative;
        padding-top: 10px;
    }

    .post-collapse__gradient, .post-attachment-collapse__gradient {
        height: 0;
    }
}

.post-message__text {
    text-align: left;
}

.post-message__text > ul, .post-code, .table-responsive {
    direction: ltr;
}

.file-attachment-menu-item-input {
    @include opacity(0);
    cursor: pointer;
    direction: ltr;
    font-size: 23px;
    height: 35px;
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    pointer-events: none;
}

.post-row__padding {
    &.bottom > div:not(.post) {
        padding-bottom: 1em;
    }
    &.top > div:not(.post) {
        padding-top: 1em;
    }

    &.top .post {
        padding-top: 22px; // this is based off of 8px padding for post + 1em
        &.same--root.same--user {
            padding-top: 1em;
        }
    }

    &.bottom .post {
        padding-bottom: 1em;
        &.same--root.same--user {
            padding-bottom: 1em;
        }
    }
}

.post-list__dynamic {
    .emoji-picker--active & {
        pointer-events: none;
    }
}

@keyframes postHighlight {
    100% {
      background: initial;
    }
}

.app__body .app__content .post.post--highlight {
    animation-duration: 1s;
    animation-name: postHighlight;
    animation-delay: 5s;
    animation-fill-mode: forwards;
}

@keyframes postPinnedOrFlaggedHighlight {
    100% {
      background: initial;
      background-color: rgba(var(--mention-highlight-bg-rgb), 0.12);
    }
}

.app__body .app__content .post.post--pinned-or-flagged-highlight {
    animation-name: postPinnedOrFlaggedHighlight;
}
