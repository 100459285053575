@charset "UTF-8";

 @font-face {
  font-family: 'compass-icons';
  src: url('../fonts/compass-icons/compass-icons.eot?93326238');
  src: url('../fonts/compass-icons/compass-icons.eot?93326238#iefix') format('embedded-opentype'),
       url('../fonts/compass-icons/compass-icons.woff2?93326238') format('woff2'),
       url('../fonts/compass-icons/compass-icons.woff?93326238') format('woff'),
       url('../fonts/compass-icons/compass-icons.ttf?93326238') format('truetype'),
       url('../fonts/compass-icons/compass-icons.svg?93326238#compass-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'compass-icons';
    src: url('../fonts/compass-icons/compass-icons.svg?93326238#compass-icons') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "compass-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-mattermost:before { content: '\e800'; } /* '' */
.icon-archive-outline:before { content: '\e801'; } /* '' */
.icon-beach-umbrella-outline:before { content: '\e802'; } /* '' */
.icon-exclamation-thick:before { content: '\e803'; } /* '' */
.icon-gfycat:before { content: '\e804'; } /* '' */
.icon-globe:before { content: '\e805'; } /* '' */
.icon-iframe-list-outline:before { content: '\e806'; } /* '' */
.icon-oauth:before { content: '\e807'; } /* '' */
.icon-power-plug-outline:before { content: '\e808'; } /* '' */
.icon-robot-happy:before { content: '\e809'; } /* '' */
.icon-server-outline:before { content: '\e80a'; } /* '' */
.icon-slash-forward-box-outline:before { content: '\e80b'; } /* '' */
.icon-webhook-incoming:before { content: '\e80c'; } /* '' */
.icon-webhook-outgoing:before { content: '\e80d'; } /* '' */
.icon-image-area-outline:before { content: '\e80e'; } /* '' */
.icon-emoticon-plus-outline:before { content: '\e80f'; } /* '' */
.icon-mark-as-unread:before { content: '\e810'; } /* '' */
.icon-arrow-back-ios:before { content: '\e811'; } /* '' */
.icon-arrow-forward-ios:before { content: '\e812'; } /* '' */
.icon-brand-zoom:before { content: '\e813'; } /* '' */
.icon-menu-variant:before { content: '\e814'; } /* '' */
.icon-message-check:before { content: '\e815'; } /* '' */
.icon-airplane-variant:before { content: '\e816'; } /* '' */
.icon-food-apple:before { content: '\e817'; } /* '' */
.icon-leaf-outline:before { content: '\e818'; } /* '' */
.icon-basketball:before { content: '\e819'; } /* '' */
.icon-emoticon-custom-outline:before { content: '\e81a'; } /* '' */
.icon-message-check-outline:before { content: '\e81b'; } /* '' */
.icon-image-broken-outline:before { content: '\e81c'; } /* '' */
.icon-format-header:before { content: '\e81d'; } /* '' */
.icon-circle-multiple-outline-lock:before { content: '\e81e'; } /* '' */
.icon-server-variant:before { content: '\e81f'; } /* '' */
.icon-server-variant-plus:before { content: '\e820'; } /* '' */
.icon-car-outline:before { content: '\e899'; } /* '' */
.icon-jumbo-attachment-text:before { content: '\e900'; } /* '' */
.icon-jumbo-attachment-word:before { content: '\e901'; } /* '' */
.icon-jumbo-attachment-excel:before { content: '\e902'; } /* '' */
.icon-jumbo-attachment-powerpoint:before { content: '\e903'; } /* '' */
.icon-jumbo-attachment-pdf:before { content: '\e904'; } /* '' */
.icon-jumbo-attachment-image:before { content: '\e905'; } /* '' */
.icon-jumbo-attachment-video:before { content: '\e906'; } /* '' */
.icon-jumbo-attachment-audio:before { content: '\e907'; } /* '' */
.icon-jumbo-attachment-generic:before { content: '\e908'; } /* '' */
.icon-jumbo-attachment-patch:before { content: '\e909'; } /* '' */
.icon-jumbo-attachment-zip:before { content: '\e90a'; } /* '' */
.icon-jumbo-attachment-code:before { content: '\e90b'; } /* '' */
.icon-jumbo-attachment-image-broken:before { content: '\e90c'; } /* '' */
.icon-account-multiple-outline:before { content: '\f00f'; } /* '' */
.icon-airplane:before { content: '\f01d'; } /* '' */
.icon-alert-outline:before { content: '\f02a'; } /* '' */
.icon-arrow-down:before { content: '\f045'; } /* '' */
.icon-arrow-left:before { content: '\f04d'; } /* '' */
.icon-arrow-up:before { content: '\f05d'; } /* '' */
.icon-at:before { content: '\f065'; } /* '' */
.icon-flask-outline:before { content: '\f096'; } /* '' */
.icon-bell-outline:before { content: '\f09c'; } /* '' */
.icon-cellphone:before { content: '\f11c'; } /* '' */
.icon-chart-bar:before { content: '\f128'; } /* '' */
.icon-chart-line:before { content: '\f12a'; } /* '' */
.icon-check:before { content: '\f12c'; } /* '' */
.icon-checkbox-blank-outline:before { content: '\f131'; } /* '' */
.icon-checkbox-marked:before { content: '\f132'; } /* '' */
.icon-chevron-down:before { content: '\f140'; } /* '' */
.icon-chevron-left:before { content: '\f141'; } /* '' */
.icon-chevron-right:before { content: '\f142'; } /* '' */
.icon-clock-outline:before { content: '\f150'; } /* '' */
.icon-close:before { content: '\f156'; } /* '' */
.icon-close-circle-outline:before { content: '\f15a'; } /* '' */
.icon-code-brackets:before { content: '\f16a'; } /* '' */
.icon-code-tags:before { content: '\f174'; } /* '' */
.icon-console:before { content: '\f18d'; } /* '' */
.icon-dots-horizontal:before { content: '\f1d8'; } /* '' */
.icon-dots-vertical:before { content: '\f1d9'; } /* '' */
.icon-email-outline:before { content: '\f1f0'; } /* '' */
.icon-emoticon-outline:before { content: '\f1f2'; } /* '' */
.icon-emoticon-happy-outline:before { content: '\f1f5'; } /* '' */
.icon-filter-variant:before { content: '\f236'; } /* '' */
.icon-fire:before { content: '\f238'; } /* '' */
.icon-flag:before { content: '\f23b'; } /* '' */
.icon-flag-outline:before { content: '\f23d'; } /* '' */
.icon-format-bold:before { content: '\f264'; } /* '' */
.icon-format-clear:before { content: '\f265'; } /* '' */
.icon-format-header-1:before { content: '\f26b'; } /* '' */
.icon-format-header-2:before { content: '\f26c'; } /* '' */
.icon-format-header-3:before { content: '\f26d'; } /* '' */
.icon-format-header-4:before { content: '\f26e'; } /* '' */
.icon-format-header-5:before { content: '\f26f'; } /* '' */
.icon-format-header-6:before { content: '\f270'; } /* '' */
.icon-format-italic:before { content: '\f277'; } /* '' */
.icon-format-list-bulleted:before { content: '\f279'; } /* '' */
.icon-format-list-numbered:before { content: '\f27b'; } /* '' */
.icon-format-strikethrough-variant:before { content: '\f281'; } /* '' */
.icon-github-circle:before { content: '\f2a4'; } /* '' */
.icon-heart-outline:before { content: '\f2d5'; } /* '' */
.icon-help:before { content: '\f2d6'; } /* '' */
.icon-information-outline:before { content: '\f2fd'; } /* '' */
.icon-laptop:before { content: '\f322'; } /* '' */
.icon-leaf:before { content: '\f32a'; } /* '' */
.icon-lightbulb-outline:before { content: '\f336'; } /* '' */
.icon-link-variant:before { content: '\f339'; } /* '' */
.icon-link-variant-off:before { content: '\f33a'; } /* '' */
.icon-lock:before { content: '\f33e'; } /* '' */
.icon-lock-outline:before { content: '\f341'; } /* '' */
.icon-magnify:before { content: '\f349'; } /* '' */
.icon-menu:before { content: '\f35c'; } /* '' */
.icon-menu-down:before { content: '\f35d'; } /* '' */
.icon-minus-box:before { content: '\f375'; } /* '' */
.icon-minus-circle:before { content: '\f376'; } /* '' */
.icon-paperclip:before { content: '\f3e2'; } /* '' */
.icon-pin:before { content: '\f403'; } /* '' */
.icon-plus:before { content: '\f415'; } /* '' */
.icon-plus-box:before { content: '\f416'; } /* '' */
.icon-refresh:before { content: '\f450'; } /* '' */
.icon-send:before { content: '\f48a'; } /* '' */
.icon-shield-outline:before { content: '\f499'; } /* '' */
.icon-sitemap:before { content: '\f4aa'; } /* '' */
.icon-soccer:before { content: '\f4b8'; } /* '' */
.icon-source-pull:before { content: '\f4c2'; } /* '' */
.icon-star:before { content: '\f4ce'; } /* '' */
.icon-star-outline:before { content: '\f4d2'; } /* '' */
.icon-sync:before { content: '\f4e6'; } /* '' */
.icon-alert-circle-outline:before { content: '\f5d6'; } /* '' */
.icon-check-circle:before { content: '\f5e0'; } /* '' */
.icon-email-variant:before { content: '\f5f0'; } /* '' */
.icon-food-fork-drink:before { content: '\f5f2'; } /* '' */
.icon-arrow-collapse:before { content: '\f615'; } /* '' */
.icon-arrow-expand:before { content: '\f616'; } /* '' */
.icon-source-branch:before { content: '\f62c'; } /* '' */
.icon-tune:before { content: '\f62e'; } /* '' */
.icon-webhook:before { content: '\f62f'; } /* '' */
.icon-eye-outline:before { content: '\f6cf'; } /* '' */
.icon-cancel:before { content: '\f739'; } /* '' */
.icon-format-quote-open:before { content: '\f756'; } /* '' */
.icon-square:before { content: '\f763'; } /* '' */
.icon-circle-outline:before { content: '\f765'; } /* '' */
.icon-account-plus-outline:before { content: '\f800'; } /* '' */
.icon-forum-outline:before { content: '\f821'; } /* '' */
.icon-settings-outline:before { content: '\f8ba'; } /* '' */
.icon-hammer:before { content: '\f8e9'; } /* '' */
.icon-pin-outline:before { content: '\f930'; } /* '擄' */
.icon-clock:before { content: '\f953'; } /* '肋' */
.icon-image-outline:before { content: '\f975'; } /* '掠' */
.icon-email-plus-outline:before { content: '\f9eb'; } /* '匿' */
.icon-file-document-outline:before { content: '\f9ed'; } /* '吝' */
.icon-layers-outline:before { content: '\f9fd'; } /* '什' */
.icon-bell-off-outline:before { content: '\fa90'; } /* '敖' */
.icon-chevron-down-circle-outline:before { content: '\fb0c'; } /* '﬌' */
.icon-format-letter-case:before { content: '\fb19'; } /* '﬙' */
.icon-download-outline:before { content: '\fb6b'; } /* 'ﭫ' */
.icon-video-outline:before { content: '\fbb8'; } /* '﮸' */
.icon-pencil-outline:before { content: '\fc92'; } /* 'ﲒ' */
.icon-camera-outline:before { content: '\fd39'; } /* 'ﴹ' */
.icon-file-video-outline:before { content: '\fe10'; } /* '︐' */
.icon-palette-outline:before { content: '\fe6c'; } /* '﹬' */
.icon-file-music-outline:before { content: '\fe7c'; } /* 'ﹼ' */
.icon-file-pdf-outline:before { content: '\fe7d'; } /* 'ﹽ' */
.icon-file-image-outline:before { content: '\fecd'; } /* 'ﻍ' */
.icon-reply-outline:before { content: '\ff3d'; } /* '］' */
.icon-draw:before { content: '\ff66'; } /* 'ｦ' */
.icon-account-outline:before { content: '󰀓'; } /* '\f0013' */
.icon-arrow-expand-all:before { content: '󰁌'; } /* '\f004c' */
.icon-file-code-outline:before { content: '󰁍'; } /* '\f004d' */
.icon-file-excel-outline:before { content: '󰁏'; } /* '\f004f' */
.icon-arrow-right:before { content: '󰁔'; } /* '\f0054' */
.icon-file-powerpoint-outline:before { content: '󰁗'; } /* '\f0057' */
.icon-file-word-outline:before { content: '󰁠'; } /* '\f0060' */
.icon-bookmark:before { content: '󰃀'; } /* '\f00c0' */
.icon-bookmark-outline:before { content: '󰃃'; } /* '\f00c3' */
.icon-dock-left:before { content: '󰃕'; } /* '\f00d5' */
.icon-chevron-up:before { content: '󰅃'; } /* '\f0143' */
.icon-close-circle:before { content: '󰅙'; } /* '\f0159' */
.icon-content-copy:before { content: '󰆏'; } /* '\f018f' */
.icon-send-outline:before { content: '󰆐'; } /* '\f0190' */
.icon-credit-card-outline:before { content: '󰆛'; } /* '\f019b' */
.icon-view-grid-outline:before { content: '󰈄'; } /* '\f0204' */
.icon-exit-to-app:before { content: '󰈆'; } /* '\f0206' */
.icon-file-outline:before { content: '󰈤'; } /* '\f0224' */
.icon-folder-outline:before { content: '󰉖'; } /* '\f0256' */
.icon-archive-arrow-up-outline:before { content: '󰊇'; } /* '\f0287' */
.icon-glasses:before { content: '󰊪'; } /* '\f02aa' */
.icon-magnify-minus:before { content: '󰍊'; } /* '\f034a' */
.icon-magnify-plus:before { content: '󰍋'; } /* '\f034b' */
.icon-menu-left:before { content: '󰍞'; } /* '\f035e' */
.icon-menu-right:before { content: '󰍟'; } /* '\f035f' */
.icon-menu-up:before { content: '󰍠'; } /* '\f0360' */
.icon-message-text-outline:before { content: '󰍪'; } /* '\f036a' */
.icon-minus:before { content: '󰍴'; } /* '\f0374' */
.icon-minus-circle-outline:before { content: '󰍷'; } /* '\f0377' */
.icon-pause:before { content: '󰏤'; } /* '\f03e4' */
.icon-play:before { content: '󰐊'; } /* '\f040a' */
.icon-radiobox-blank:before { content: '󰐽'; } /* '\f043d' */
.icon-radiobox-marked:before { content: '󰐾'; } /* '\f043e' */
.icon-sort-alphabetical-ascending:before { content: '󰖽'; } /* '\f05bd' */
.icon-playlist-check:before { content: '󰗇'; } /* '\f05c7' */
.icon-check-circle-outline:before { content: '󰗡'; } /* '\f05e1' */
.icon-help-circle-outline:before { content: '󰘥'; } /* '\f0625' */
.icon-circle-multiple-outline:before { content: '󰚕'; } /* '\f0695' */
.icon-update:before { content: '󰚰'; } /* '\f06b0' */
.icon-eye-off-outline:before { content: '󰛑'; } /* '\f06d1' */
.icon-infinity:before { content: '󰛤'; } /* '\f06e4' */
.icon-arrow-right-bold-outline:before { content: '󰧂'; } /* '\f09c2' */
.icon-trash-can-outline:before { content: '󰩺'; } /* '\f0a7a' */
.icon-account-minus-outline:before { content: '󰫬'; } /* '\f0aec' */
.icon-calendar-outline:before { content: '󰭧'; } /* '\f0b67' */
.icon-export-variant:before { content: '󰮓'; } /* '\f0b93' */
.icon-folder-plus-outline:before { content: '󰮝'; } /* '\f0b9d' */
.icon-calendar-check-outline:before { content: '󰱄'; } /* '\f0c44' */
.icon-hand-right:before { content: '󰹇'; } /* '\f0e47' */
.icon-notebook-outline:before { content: '󰺿'; } /* '\f0ebf' */
.icon-shield-alert-outline:before { content: '󰻍'; } /* '\f0ecd' */
.icon-slash-forward:before { content: '󰿟'; } /* '\f0fdf' */
.icon-file-multiple-outline:before { content: '󱀲'; } /* '\f1032' */
.icon-message-plus-outline:before { content: '󱂻'; } /* '\f10bb' */
.icon-message-minus-outline:before { content: '󱅯'; } /* '\f116f' */
.icon-crown-outline:before { content: '󱇐'; } /* '\f11d0' */
.icon-folder-move-outline:before { content: '󱉆'; } /* '\f1246' */
